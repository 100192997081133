import React, { useEffect, useState } from "react";
import styles from "./Header.module.css";

function Header() {
	return (
		<div className={styles.container}>
			<div id={styles.name}>
				<span id={styles.fname}>Chris</span>
				&nbsp;
				<span id={styles.lname}>Elliott</span>
			</div>
			<div id="navBadge" className={styles.badges}>
				<a href="mailto:thecerealcoder@gmail.com?subject:Contact Inquiry">
					<i className="fas fa-envelope fa-2x"></i>
				</a>
				<a target="_blank" rel="noopener noreferrer" href="https://github.com/thecerealcoder">
					<i className="fab fa-github-square fa-2x"></i>
				</a>
				<a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/christopher-elliott-15a954139/">
					<i className="fab fa-linkedin-in fa-2x"></i>
				</a>
				<a target="_blank" rel="noopener noreferrer" href="https://www.thecerealcoder.com/">
					<i className="fas fa-comment fa-2x"></i>
				</a>
			</div>
		</div>
	);
}

export default Header;
